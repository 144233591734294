<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addDeviceWater">添加水肥机</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入水肥机名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="id" label="序号" align="center"></el-table-column>
      <el-table-column prop="name" label="水肥机名称" show-overflow-tooltip align="center"></el-table-column>
      <!--      <el-table-column prop="node" label="上级节点" show-overflow-tooltip align="center"></el-table-column>-->
      <!--      <el-table-column prop="" label="通道数量" show-overflow-tooltip align="center"></el-table-column>-->
      <!--      <el-table-column prop="" label="配置进度" show-overflow-tooltip align="center"></el-table-column>-->
      <el-table-column prop="state" label="状态" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.state" active-value="1" inactive-value="0" active-color="#13ce66"
                     inactive-color="#ff4949" @change="test(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="400">
        <template slot-scope="scope">
          <el-button @click="detail(scope.row)" type="success" size="small">配置</el-button>
          <el-button @click="edit(scope.row)" type="success" size="small">编辑</el-button>
          <el-button @click="del(scope.row)" type="success" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="sizeChange1"
          @current-change="currentChange1"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加修改 -->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="水肥机名称" prop="name">
            <el-input v-model="addForm.name" placeholder="请输入水肥机名称"></el-input>
          </el-form-item>
          <!--          <el-form-item label="选择模板" prop="tempId">-->
          <!--            <el-select v-model="addForm.tempId" placeholder="选择模板" @change="selTemp">-->
          <!--              <el-option-->
          <!--                  v-for="item in option"-->
          <!--                  :key="item.id"-->
          <!--                  :label="item.name"-->
          <!--                  :value="item.id"-->
          <!--              ></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="通道数量" prop="number">-->
          <!--            <el-radio v-model="radio" label="1">单通道</el-radio>-->
          <!--            <el-radio v-model="radio" label="2">三通道</el-radio>-->
          <!--            <el-radio v-model="radio" label="3">四通道</el-radio>-->
          <!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!--    抽屉-->
    <div class="drawerBox">
      <el-drawer title="设备列表" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
        <div class="drawer">
          <el-button type="success" size="small" @click="addDevice(deviceId)">添加设备</el-button>
          <el-table
              :data="drawerTableData" style="width: 100%; margin-top: 1vh;">
            <el-table-column prop="name" label="设备名称" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="deviceNumber" label="设备编号" show-overflow-tooltip align="center"></el-table-column>
            <!--            <el-table-column prop="config" label="配置情况" show-overflow-tooltip align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                <span v-if="scope.row.config==0" style="color: red">未配置</span>-->
            <!--                <span v-if="scope.row.config==1" style="color: green">已配置</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="deviceType" label="设备类型" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.deviceType==20">2.0继电器</span>
                <span v-if="scope.row.deviceType==2">1.0继电器</span>
                <span v-if="scope.row.deviceType==4">传感器</span>
              </template>
            </el-table-column>
            <!--            <el-table-column prop="onLineState,onLine" label="设备状态" show-overflow-tooltip align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                <span v-if="scope.row.onLineState==0" style="color: red">离线</span>-->
            <!--                <span v-if="scope.row.onLineState==1" style="color: green">在线</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="templateId" label="模板" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div v-for="item in tempList" :key="item.id">
                  <div type="text" v-if="item.id == scope.row.templateId">{{ item.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="采集时间" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button @click="configure(scope.row)" type="success" size="small" class="btn">配置</el-button>
                <el-button @click="editDevice(scope.row)" type="success" size="small" class="btn">修改</el-button>
                <el-button @click="drawerDel(scope.row)" type="success" size="small" class="btn">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block pagingBox">
            <el-pagination
                @size-change="sizeChange2"
                @current-change="currentChange2"
                :current-page="listPage"
                :page-sizes="[100, 200]"
                :page-size="listSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="listTotal"
            >
            </el-pagination>
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 添加设备 -->
    <el-dialog title="" :visible.sync="deviceBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ deviceTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForms" :rules="sensorRules" :model="addForms" label-width="10vw">
          <el-form-item label="设备编号" prop="deviceNumber">           
            <el-input v-if="deviceTitle == '新增'" v-model="addForms.deviceNumber" placeholder="请输入设备编号"></el-input>
            <el-input v-else v-model="addForms.deviceNumber" placeholder="请输入设备编号" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备名称" prop="name">
            <el-input v-model="addForms.name" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="选择模板" prop="templateId">
            <el-select v-model="addForms.templateId" placeholder="选择模板" @change="selTemp">
              <el-option
                  v-for="(item,i) in tempList"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备类型" prop="deviceType">
            <el-select v-model="addForms.deviceType" placeholder="选择类型" @change="selType" v-if="deviceTitle == '新增'">
              <el-option
                  v-for="(item,i) in typeList"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
            <el-select v-model="addForms.deviceType" disabled placeholder="选择类型" @change="selType" v-else>
              <el-option
                  v-for="(item,i) in typeList"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlags">
          <el-button type="info" @click="deviceBox = false">取消</el-button>
          <el-button type="primary" @click="saveDevice('addForms')">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!--    抽屉内配置弹窗-->
    <div class="drawerBox">
      <el-drawer title="节点列表" :visible.sync="drawerIn" :direction="direction" :before-close="handleCloseIn">
        <div class="drawer">
          <el-table
              :data="sensorData" style="width: 100%; margin-top: 1vh;">
            <el-table-column prop="sensorName" label="节点名称" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="sensorCode" label="节点编号" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="createTime" label="采集时间" show-overflow-tooltip align="center"></el-table-column>
          </el-table>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      listPage: 1,
      listSize: 100,
      listTotal: 0,
      loading: false,
      formTitle: "",
      deviceTitle: '',
      messageBox: false,
      showFlag: false,
      showFlags: false,
      radio: '1',
      ids: 0,
      searchName: '',
      addForm: {},
      addForms: {},
      rules: {
        name: [{required: true, message: "设备名称不可为空", trigger: ["blur", "change"]},],

      },
      sensorRules: {
        deviceNumber: [{required: true, message: "设备编号不可为空", trigger: ["blur", "change"]},],
        name: [{required: true, message: "设备名称不可为空", trigger: ["blur", "change"]},],
        templateId: [{required: true, message: "模板不可为空", trigger: ["blur", "change"]},],
        deviceType: [{required: true, message: "请选择设备类型", trigger: ["blur", "change"]}],
      },
      direction: 'rtl',
      drawer: false,
      formTitles: '',
      drawerTableData: [],
      sensorData: [],
      drawerMessageBox: false,
      deviceBox: false,
      tempList: [],
      drawerIn: false,
      deviceId: 0,
      typeList: [
        {id: 20, name: '2.0继电器'},
        {id: 2, name: '1.0继电器'},
        {id: 4, name: '传感器', disabled: false},],
      addDis: true,
      editDis: false
    };
  },
  mounted() {
    this.getList();
    this.getSelTemp();
  },
  methods: {
    selType(val) {
      //console.log("类型", val)
      let data = val;
      this.templateId = data
    },
    change(e) {
      this.$forceUpdate()
    },
    //请求列表数据
    getList() {
      this.$get("/deviceManage/getDevicesList", {
        page: this.page,
        limit: this.size,
        name: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.datas;
          if(m.length > 0){
            this.tableData = m;
            this.total = res.data.count;
          } else {
            this.page = 1;
            this.getList()
          }
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 选择模板下拉框
    selTemp(val) {
      //console.log("点击了----")
      let data = val
      this.deviceName = data;

    },
// 模板列表
    getSelTemp() {
      this.$post('/deviceManage/getTempList', {}).then((res) => {
        if (res.data.state == "success") {
          this.tempList = res.data.datas;
        }
      })
    },
    //确认提交
    saveForm(formName) {
      let url;
      let id;
      if (this.formTitle == '新增') {
        url = '/deviceManage/upDevice';
      } else if (this.formTitle == '编辑') {
        url = '/deviceManage/upDevice';
        id = this.ids;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, {
            id: id,
            name: this.addForm.name,
            // tempId: this.addForm.tempId
          }).then((res) => {
            if (res.data.state == 'success') {
              this.$message.success('操作成功')
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error('操作失败！请稍后重试')
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addDeviceWater() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    //编辑
    edit(item) {
      this.ids = item.id;
      this.formTitle = "编辑";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(item));
      });
    },
    //删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$get("/deviceManage/deleteDevice", {
          id: item.id,
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message.success("删除成功");
            this.getList();
            this.search()
          } else {
            this.$message.info("删除失败");
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    // 配置详情
    detail(item) {
      this.ids = item.id;
      this.title = "设备列表";
      this.drawer = true;
      this.addDevice(item.id);
      // //console.log('item.id',item.id)
      this.deviceBox = false;
      this.getDeviceList(item.id)

    },

    // 设备列表
    getDeviceList(id) {
      this.$post('/deviceManage/getAllDeviceLists', {
        page: this.listPage,
        limit: this.listSize,
        deviceId: id,
        deviceCode: '',
        groupId: 0,
        name: "",
      }).then((res) => {
        if (res.data.state == "success") {
          this.drawerTableData = res.data.datas;
          this.listTotal = res.data.count;
        }
      })
    },
// 抽屉内配置
    configure(itemss) {
      //console.log('itemss', itemss)
      let number = itemss.deviceNumber;
      this.drawerIn = true;
      this.drawer = true;
      this.getGetDeviceSensorList(number);
      //console.log(number)
    },
    // 获取节点列表
    getGetDeviceSensorList(number) {
      this.$get('/deviceManage/getDeviceSensorList', {
        sensorNcode: number,
      }).then((res) => {
        if (res.data.state == 'success') {
          //console.log(res.data)
          this.sensorData = res.data.datas;
        }
      })
    },

    // 抽屉内删除
    drawerDel(drawerItem) {
      // //console.log('drawerItem',drawerItem)
      let type = drawerItem.deviceType;
      // //console.log('type',type)
      let url;
      if (type == '3') {
        url = '/device/delDeviceUser';
      // } else if (type == '20') {
        } else if (type == '2' || type == '20') {
        url = '/relay/delDevice';
      }

      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$get(url, {
          deviceNumber: drawerItem.deviceNumber,
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message.success("删除成功");
            this.drawer = false;
            this.detail()
          } else {
            this.$message.info("删除失败");
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    // 开关回调
    test: function (e) {
      this.$post('/deviceManage/upDeviceState', {
        id: e.id,
        state: e.state
      }).then((res) => {
        if (res.data.state == "success") {
          //console.log(res.data)
        }
      })
    },
// 搜索
    search() {
      this.page = 1;
      this.getList()
    },

    handleClose() {
      this.drawer = false;
    },
    handleCloseIn() {
      this.drawerIn = false;
    },
    // 水肥机列表分页
    sizeChange1(val) {
      this.size = val;
      this.getList()
    },
    currentChange1(val) {
      this.page = val;
      this.getList()
    },

    sizeChange2(val) {
      this.listSize = val;
      this.getDeviceList()
    },
    currentChange2(val) {
      this.listPage = val;
      this.getDeviceList()
    },
    // 添加设备（在弹窗内）
    addDevice(deviceId) {
      this.deviceId = deviceId
      this.deviceTitle = "新增"
      // //console.log('----deviceId',deviceId)
      this.deviceBox = true;
      this.showFlags = true;
      this.$nextTick(() => {
        this.addForms = {};
        this.$refs.addForms.resetFields();
      });
    },
    // 修改设备
    editDevice(items) {
      //console.log('items', items)
      this.ids = items.id;
      //console.log(this.ids)
      this.deviceBox = true;
      this.showFlags = true;
      this.deviceTitle = "编辑";
      this.addDis = false;
      this.editDis = true;
      this.$nextTick(() => {
        this.addForms = JSON.parse(JSON.stringify(items));
        //console.log(this.addForms)
      });
      this.addForms.templateId = items.templateId
      //console.log('items.templateId', items.templateId)
      this.addForms.deviceType = items.deviceType;
      // //console.log(this.addForms.deviceType)
      // //console.log(items.deviceType)
      // if (items.deviceType == '3'){
      //   items.deviceType='继电器'
      // }else if(items.deviceType == '4'){
      //   items.deviceType='传感器'
      // }

    },

    // 提交添加修改设备
    saveDevice(formName) {
      let editId = this.ids;
      let type = this.addForms.deviceType
      //console.log('type', type)
      //console.log('this.addForms.tempId', this.addForms.templateId)
      // //console.log(this.deviceId)
      let id = this.deviceId;
      let num = this.addForms.deviceNumber;
      let tempId = this.addForms.templateId;
      let url;
      if (this.deviceTitle == '新增') {
        // if (type == '20') {
          if (type == '2' || type == '20') {
          url = '/relay/addDevice';
        } else {
          url = '/device/addUserDevices';
        }
      } else if (this.deviceTitle == '编辑') {
        // if (type == '20') {
          if (type == '2' || type == '20') {
          url = '/relay/editDevice'
        } else {
          url = '/device/upDeviceInfo';
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, {
            deviceNumber: num,
            name: this.addForms.name,
            deviceId: id,
            id: editId,
            deviceType: this.addForms.deviceType,
            templateId: tempId
          }).then((res) => {
            if (res.data.state == 'success') {
              this.$message.success("操作成功")
              this.deviceBox = false
              this.getDeviceList(id)
              this.getChangeDeviceTemplate(num, tempId)
            } else {
              this.$message.error(res.data.msg)
              this.deviceBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加设备后调用这个接口添加模板节点编号
    getChangeDeviceTemplate(num, tempId) {
      //console.log('tempId', tempId)
      this.$post('/template/changeDeviceTemplate', {
        deviceNumber: num,
        id: tempId,
      }).then((res) => {
        //console.log(res)
        if (res.data.state == "success") {
          //console.log(res)
        }

      })
    }

  }
}
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content .drawerBox /deep/ .el-table {
  max-height: 75vh;
  overflow-y: scroll;
}


#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .el-drawer {
  width: 47vw !important;
}

.icons {
  width: 30px !important;
}

.icons img {
  display: inline-block;
  width: 20%;
}

.drawer .btn {
  background-color: transparent !important;
  color: #67C23A !important;
  padding: 2px 2px !important;
}


</style>
